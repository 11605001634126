import React, { Component } from 'react'

export class FooterListLink extends Component {
  render() {
    return (
        <li><a href={this.props.href}>{this.props.text}</a></li>
    )
  }
}

export default FooterListLink