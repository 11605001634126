import React, { Component } from 'react';
import AnnouncementBanner from '../components/AnnouncementBanner';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import "../styles/pages/Article.css";
import tech_img from "../assets/tech_consulting.jpg";

const article = {
    image: tech_img,
    title: 'How to Start a Successful Online Business',
    date: 'March 14, 2023',
    content: [
      'Starting an online business can be a lucrative and rewarding venture. However, it takes careful planning and execution to ensure success.',
      'Here are some steps you can take to start a successful online business:',
      '1. Find your niche',
      '2. Validate your business idea',
      '3. Develop a business plan',
      '4. Choose the right e-commerce platform',
      '5. Build your website',
      '6. Optimize for search engines',
      '7. Create a marketing strategy',
      'By following these steps and staying focused on your goals, you can start a successful online business and achieve the financial freedom and flexibility you desire.',
    ]
  };

export class ArticlePage extends Component {
  render() {
    return (
        <div>
       {/* Annoucement Banner */}
       <AnnouncementBanner/>
      
      {/* Navigation */}          
      <Navigation/>
      
      {/* Navigation
      <SearchBanner/> */}
        <main>
        {/* <div className="article-page">
        <div className="article-header">
          <img src={article.image} alt={article.title} />
          <h1>{article.title}</h1>
          <p>{article.date}</p>
        </div>
        <div className="article-body">
          <p>{article.content}</p>
        </div>
      </div> */}
      <div className="article-page">
      <div className="article-header">
        <img src={article.image} alt={article.title} />
        <h1>{article.title}</h1>
        <p>{article.date}</p>
      </div>
      <div className="article-body">
        {article.content.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    </div>
      </main>
      <Footer/>
      </div>
    )
  }
}

export default ArticlePage