import React, { Component } from 'react';
import AnnouncementBanner from '../components/AnnouncementBanner'
import Navigation from '../components/Navigation'
import ToC from '../components/legal/ToC';
import Footer from '../components/Footer'

export class TermsAndConditions extends Component {
  render() {
    return (
      <div>
        {/* Annoucement Banner */}
        <AnnouncementBanner/>
        
        {/* Navigation */}          
        <Navigation/>
        
        {/* Terms and Conditions */}
        <ToC/>
        
        {/* Footer */}
        <Footer/>
      </div>
    )
  }
}
