import React, { Component } from 'react';
import AnnouncementBanner from '../components/AnnouncementBanner';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import "../styles/pages/NewsPage.css";
import tech_img from "../assets/tech_consulting.jpg";

const articles = [
  {
    id: 1,
    image: tech_img,
    title: 'How to Start a Successful Online Business',
    description: 'Learn the steps you need to take to start a successful online business, from finding your niche to marketing your products.',
    link: '/insights/v1',
  },
  {
    id: 2,
    image: tech_img,
    title: 'The Benefits of Remote Work for Employees and Employers',
    description: 'Remote work has become increasingly popular in recent years. Learn about the benefits for both employees and employers.',
    link: '/insights/v1',
  },
  {
    id: 3,
    image: tech_img,
    title: 'The Importance of Branding for Small Businesses',
    description: 'Branding is essential for small businesses looking to stand out in a crowded marketplace. Learn why it matters and how to do it right.',
    link: '/articles/3',
  },
  {
    id: 4,
    image: tech_img,
    title: 'How to Build a Strong Online Presence for Your Business',
    description: 'In today\'s digital age, having a strong online presence is essential. Learn how to build one that helps your business succeed.',
    link: '/articles/4',
  },
  {
    id: 5,
    image: tech_img,
    title: 'The Future of Artificial Intelligence in Business',
    description: 'Artificial intelligence is poised to revolutionize the business world. Learn about the potential applications and benefits for your business.',
    link: '/articles/5',
  },
];

export class InsightsPage extends Component {
  render() {
    return (
      <div>
       {/* Annoucement Banner */}
       <AnnouncementBanner/>
      
      {/* Navigation */}          
      <Navigation/>
      
      {/* Navigation
      <SearchBanner/> */}

      <main>
      <div className="news-section">
      <div className="column">
        {articles.slice(0, Math.ceil(articles.length / 2)).map((article) => (
          <div className="news-card" key={article.id}>
            <img src={article.image} alt={article.title} />
            <h3>{article.title}</h3>
            <p>{article.description}</p>
            <a href={article.link}>Read More</a>
          </div>
        ))}
      </div>
      <div className="column">
        {articles.slice(Math.ceil(articles.length / 2)).map((article) => (
          <div className="news-card" key={article.id}>
            <img src={article.image} alt={article.title} />
            <h3>{article.title}</h3>
            <p>{article.description}</p>
            <a href={article.link}>Read More</a>
          </div>
        ))}
      </div>
    </div>
      </main>
      <Footer/>
      </div>
    )
  }
}

export default InsightsPage