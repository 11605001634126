import React, { Component } from 'react';
import AnnouncementBanner from '../components/AnnouncementBanner'
import Footer from '../components/Footer'
import HeroBanner from '../components/HeroBanner'
import Navigation from '../components/Navigation'
import SearchBanner from '../components/SearchBanner'
import PrivacyPolicy from '../components/legal/PrivacyPolicy';

export class PrivacyPage extends Component {
  render() {
    return (
      <div>
        {/* Annoucement Banner */}          
        <AnnouncementBanner/>
        
        {/* Navigation */}          
        <Navigation/>
        
        {/* Privacy Policy */}
        <PrivacyPolicy/>

        {/* Footer */}
        <Footer/>
      </div>
    )
  }
}

