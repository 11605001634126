import React, { Component } from 'react'

export class ToC extends Component {
  render() {
    return (
        <main>
        <h2>Terms and Conditions</h2>
        <p>Effective Date: [Insert date]</p>
        <p>Thank you for visiting our website located at [insert website URL] (the “Site”). These Terms and Conditions govern your use of the Site, and by using the Site, you agree to be bound by these Terms and Conditions.</p>
  
        <h3>Use of Site</h3>
        <p>You may use the Site only for lawful purposes and in accordance with these Terms and Conditions. You agree not to use the Site:</p>
        <ul>
          <li>In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries);</li>
          <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise;</li>
          <li>To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter”, “spam”, or any other similar solicitation;</li>
          <li>To impersonate or attempt to impersonate our company, a company employee, another user, or any other person or entity;</li>
          <li>To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Site, or which, as determined by us, may harm our company or users of the Site or expose them to liability.</li>
        </ul>
  
        <p>We reserve the right to terminate or suspend your access to the Site, without notice or liability, for any reason, including if we believe that you have violated or acted inconsistently with the letter or spirit of these Terms and Conditions.</p>
  
        <h3>Intellectual Property</h3>
        <p>The Site and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by our company, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
  
        <p>You may not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Site, except as follows:</p>
        <ul>
          <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials;</li>
          <li>You may store files that are automatically cached by your Web browser for display enhancement purposes; and</li>
          <li>You may print or download one copy of a reasonable number of pages of the Site for your own personal, non-commercial use and not for further reproduction, publication, or distribution.</li>
        </ul>
  
        <p>If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</p>
  
        <p>If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Site in breach of these Terms
  
        and Conditions, your right to use the Site will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. Any unauthorized use may also violate applicable laws, including without limitation copyright and trademark laws and applicable communications regulations and statutes.</p>
  
  <h3>Disclaimer of Warranties</h3>
  <p>THE CONTENT, SERVICES, INFORMATION, AND ACCESS TO THE SITE ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND THOSE ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE.</p>
  
  <p>WE DO NOT WARRANT THAT THE CONTENT, SERVICES, INFORMATION, OR ACCESS TO THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE OR THE SERVERS THAT MAKE IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
  
  <h3>Limitation of Liability</h3>
  <p>IN NO EVENT WILL OUR COMPANY, ITS AFFILIATES, LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITE, CONTENT, SERVICES, INFORMATION, OR ANY LINKED SITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.</p>
  
  <h3>Indemnification</h3>
  <p>You agree to indemnify, defend, and hold harmless our company, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms and Conditions or your use of the Site, including, but not limited to, your User Contributions, any use of the Site’s content, services, and products other than as expressly authorized in these Terms and Conditions, or your use of any information obtained from the Site.</p>
  
  <h3>Termination</h3>
  <p>These Terms and Conditions are effective unless and until terminated by either you or us. You may terminate these Terms and Conditions at any time by discontinuing use of the Site.</p>
  
  <p>We may also terminate or suspend these Terms and Conditions, or any part of them, immediately and without notice or liability, for any reason, including if we believe that you have breached these Terms and Conditions or if we decide to discontinue the Site or any part thereof.</p>
  
  <h3>Entire Agreement</h3>
  <p>These Terms and Conditions and our Privacy Policy constitute the entire agreement between you and us regarding the use of the Site, superseding any prior agreements between you and us relating to the Site.</p>
  
  <h3>Governing Law and Jurisdiction</h3>
        <p>All matters relating to the Site and these Terms and Conditions, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the laws of the State of Texas without giving effect to any choice or conflict of law provision or rule.</p>
  
        <p>Any legal suit, action, or proceeding arising out of, or related to, these Terms and Conditions or the Site shall be instituted exclusively in the federal courts of the United States or the courts of the State of Texas in each case located in the City of Dallas and County of Dallas although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms and Conditions in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
  
        <h3>Waiver and Severability</h3>
        <p>No waiver by our company of any term or condition set forth in these Terms and Conditions shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of our company to assert a right or provision under these Terms and Conditions shall not constitute a waiver of such right or provision.</p>
  
        <p>If any provision of these Terms and Conditions is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms and Conditions will continue in full force and effect.</p>
  
        <h3>Changes to Terms and Conditions</h3>
        <p>We may revise and update these Terms and Conditions from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Site thereafter. Your continued use of the Site following the posting of revised Terms and Conditions means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.</p>
  
        <h3>Contact Us</h3>
        <p>If you have any questions or concerns about these Terms and Conditions or our privacy practices, please contact us at [insert contact email or mailing address].</p>
      </main>
    )
  }
}

export default ToC