import React, { Component } from 'react'

export class FooterLinkDivider extends Component {
  render() {
    return (
    <span class="divider">&nbsp;|&nbsp;</span>
    )
  }
}

export default FooterLinkDivider