
import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Switch, Route, Redirect, Routes} from "react-router-dom";
import HomePage from './pages/HomePage';
import { Component } from 'react';
import InsightsPage from './pages/InsightsPage';
import ServicesPage from './pages/ServicesPage';
import NewsPage from './pages/NewsPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import ArticlePage from './pages/ArticlePage';
// import PrivacyPolicy from './pages/PrivacyPolicy';
import { PrivacyPage } from './pages/PrivacyPage';
import { TermsAndConditions } from './pages/TermsAndConditions';
import NewsletterPage from './pages/NewsletterPage';

class App extends Component {
  render() {
  return (
    <Router>
      <div className="App"> 
      <Switch>
        <Route exact path="/" component={HomePage}/>
        <Route exact path="/services" component={ServicesPage}/>
        <Route exact path="/insights" component={InsightsPage}/>
        <Route exact path="/insights/v1" component={ArticlePage}/>
        <Route exact path="/news" component={NewsPage}/>
        <Route exact path="/newsletter" component={NewsletterPage}/>
        <Route exact path="/about" component={AboutPage}/>
        <Route exact path="/contact-us" component={ContactPage}/>
        <Route exact path="/privacy" component={PrivacyPage}/>
        <Route exact path="/terms" component={TermsAndConditions}/>
      </Switch>
      </div>
    </Router>
  );
}
}

export default App;
