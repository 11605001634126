import React, { Component } from 'react';
import "../styles/components/HeroBanner.css";
import hero_img from "../assets/hero-image.jpg";
import C2ActionBlock from './Call2ActionBlock';
import wrkf from "../assets/wrkf.png";
import wrkf1 from "../assets/wrkf_1.JPG";
export class HeroBanner extends Component {
  render() {
    return (
      //   <div className="hero-section">
      //   <div className="hero-content">
        
      //   <C2ActionBlock header="How Interest Rate Changes are Affecting your Business" subheader="Is the tightening cycle over yet? Discover what a slowdown can do for your business." text="Read More" link="/insights/"/>
      //   </div>
      //   <img src={hero_img} alt="Hero Image" className="hero-image"/>
      //   <div className='overlay'></div>
      // </div>
      <div className="hero-container">
      <div className="hero-image">
        <img src={hero_img} alt="Hero Image" className="hero-image"/>
        
        <div className="hero-overlay"></div>
      </div>
      <div className="hero-content">
        <h1>Will Fullerton</h1>
        {/* <img src={wrkf} alt="John Doe" /> */}
        <p>Entrepreneur. Consultant. Writer.</p>
        <a href="/about">
        <button>Learn More</button>
        </a>
      </div>
    </div>


    )
  }
}

export default HeroBanner