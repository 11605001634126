import React, { Component } from 'react'

export class FooterSocialLink extends Component {
  render() {
    return (
        <li className="footer-follow-link">
        <a target="__blank" className="footer-gray-social-link" href={this.props.link}>
        {this.props.icon}
        </a>
    </li>
    )
  }
}

export default FooterSocialLink