import React, { Component } from 'react';
import "../styles/components/Footer.css";
import logo from "../assets/logo.png";
import { Facebook, Instagram, LinkedIn, YouTube, Twitter } from '@mui/icons-material';
import { currentYear } from '../helpers';
import FooterSocialLink from './link/FooterSocialLink';
import FooterLegalLink from './link/FooterLegalLink';
import FooterLinkDivider from './link/FooterLinkDivider';
import FooterListLink from './link/FooterListLink';
import FooterLinkHeading from './heading/FooterLinkHeading';



export class Footer extends Component {
  render() {
    return (
        <footer className='footer-container'>
            <div className="footer-internal">
                <div className="footer-social-bar">
                    <ul className='footer-social-follow'>
                        <FooterSocialLink link="https://twitter.com/wrkfullerton" icon={<Twitter/>}/>
                        <FooterSocialLink link="https://www.linkedin.com/company/wrkfullerton" icon={<LinkedIn/>}/>
                        <FooterSocialLink link="https://www.instagram.com/wrkfullerton" icon={<Instagram/>}/>
                        <FooterSocialLink link="https://www.youtube.com/@wrkfullerton" icon={<YouTube/>}/>
                    </ul>
                </div>

            <div className="footer__row">
                <div className="footer-nav-list">
                    <div className="footer__column">
                    <h4 className="footer__heading">Services</h4>
                    <ul className="footer__list">
                        <FooterListLink href="/services#entity-formation" text="Entity Formation"/>
                        <FooterListLink href="/services#web-development" text="Web Development"/>
                        <FooterListLink href="/services#brand-development" text="Brand Development"/>                          
                        <FooterListLink href="/services#business-consulting" text="Business Consulting"/>                          
                        <FooterListLink href="/services#technology-consulting" text="Technology Consulting"/>                          
                    </ul>
                </div>
        
            <div className="footer__column">

            <FooterLinkHeading text="Articles"/>
                <ul className="footer__list">
                    <FooterListLink href="/insights" text="Technology"/>
                    <FooterListLink href="/insights" text="Markets"/>
                    <FooterListLink href="/insights" text="Business"/>                          
                </ul>

            
            {/* <FooterLinkHeading text="Research"/>
                <ul className="footer__list">
                    <FooterListLink href="/insights" text="Technology"/>
                    <FooterListLink href="/insights" text="Markets"/>
                    <FooterListLink href="/insights" text="Business"/>                          
                </ul> */}

                {/* <FooterLinkHeading text="Newsletter"/>
                <ul className="footer__list">
                    <FooterListLink href="/news" text="Join our Newsletter"/>
                </ul> */}
            </div>

            <div className="footer__column">
                
                <FooterLinkHeading text="Newsletter"/>
                <ul className="footer__list">
                    <FooterListLink href="/news" text="Join our Newsletter"/>
                </ul>
            </div>

            <div className="footer__column">                
                <FooterLinkHeading text="About"/>
                <ul className="footer__list">
                    <FooterListLink href="/about" text="About Will"/>                
                </ul>
            </div>

            <div className="footer__column">
                <FooterLinkHeading text="Contact Us"/>
                <ul className="footer__list">
                    <FooterListLink href="/contact-us" text="Contact Form"/>
                    <FooterListLink href="/contact-us" text="Contact Information"/>
                </ul>
            </div>
            </div>
            </div>

            {/* pre-footer */}
            <div className="footer__legal__row">
                <div className="footer__legal__nav">
                    <nav>
                    <FooterLegalLink href="/privacy" text="Privacy"/>
                    <FooterLinkDivider/>
                    <FooterLegalLink href="/terms" text="Terms of Use"/>
                    <FooterLinkDivider/>
                    <FooterLegalLink href="/accessibility" text="Accessibility"/>
                    <FooterLinkDivider/>
                    <FooterLegalLink href="/cookie-policy" text="Cookies Policy"/>
                    <FooterLinkDivider/>
                    <FooterLegalLink href="/disclosures" text="Regulatory Disclosures"/>
                    </nav>
                </div>
                <div className="footer__copyright__legal">
                    <div className="footer__copyright">    
                    <span> ©</span>
                    <span> {currentYear}</span> 

                    <span> WRKFullerton & Co. All rights Reserved.</span>
                    </div>
                </div>
            </div>

        </div>
        
      </footer>
    )
  }
}

export default Footer