import React, { Component } from 'react'

export class PrivacyPolicy extends Component {
  render() {
    return (
        <main>
        <h2>Privacy Policy</h2>
        <p>Effective Date: [Insert date]</p>
        <p>Thank you for visiting our website located at [insert website URL] (the “Site”). This Privacy Policy explains how we collect, use, and disclose personal information that we obtain from you through our Site.</p>
  
        <h3>Information We Collect</h3>
        <p>When you visit our Site, we may collect certain information about your device and browsing activity, including your IP address, browser type, referring/exit pages, and other usage information. We may use cookies and other tracking technologies to collect this information. In addition, if you contact us through our contact form, we may collect your name, email address, and any other information you choose to provide.</p>
  
        <p>We also use Google Analytics to collect information about your use of our Site. Google Analytics is a web analytics service provided by Google Inc. (“Google”). Google Analytics uses cookies and similar technologies to collect information about your use of the Site and to create reports based on that information. You can learn more about Google Analytics and how it collects and processes data at www.google.com/policies/privacy/partners/.</p>
  
        <h3>How We Use Your Information</h3>
        <p>We use the information we collect to:</p>
        <ul>
          <li>Respond to your inquiries and requests</li>
          <li>Improve our Site and services</li>
          <li>Analyze trends and user activity on our Site</li>
          <li>Provide you with updates and marketing communications about our services, if you have opted in to receive them</li>
        </ul>
  
        <p>We may also use and disclose your information as necessary or appropriate:</p>
        <ul>
          <li>To comply with applicable laws and regulations</li>
          <li>To respond to a subpoena, court order, or other legal process</li>
          <li>To investigate or prevent fraud or other illegal activities</li>
          <li>To protect the rights and property of our company or others</li>
        </ul>
  
        <h3>How We Share Your Information</h3>
        <p>We may share your information with third-party service providers who perform services on our behalf, such as website hosting, data analysis, and customer service. These third-party service providers are not authorized by us to use or disclose your information except as necessary to perform services on our behalf or to comply with legal requirements.</p>
  
        <p>We may also share your information with our affiliates and subsidiaries, or in connection with a merger, acquisition, or sale of all or a portion of our assets.</p>
  
        <h3>How We Protect Your Information</h3>
        <p>We take reasonable and appropriate measures to protect your information from unauthorized access, disclosure, and misuse. However, no security measures are 100% effective and we cannot guarantee the security of your information.</p>
  
        <h3>Your Choices</h3>
        <p>You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. However, if you disable or refuse cookies, some parts of our Site may be inaccessible or not function properly.</p>
  
        <p>You may opt out of Google Analytics by installing the Google Analytics Opt-Out Browser Add-On, available at https</p>
  
        <p>You may also opt out of receiving marketing communications from us at any time by following the instructions included in those communications.</p>
  
        <h3>Children’s Privacy</h3>
        <p>Our Site is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13 without parental consent. If you are under 13, do not use or provide any information on this Site.</p>
  
        <h3>Changes to this Privacy Policy</h3>
        <p>We may update this Privacy Policy from time to time by posting a new version on our Site. If we make any material changes, we will notify you by email or by posting a notice on our Site prior to the effective date of the changes.</p>
  
        <h3>Contact Us</h3>
        <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at [insert contact email or mailing address].</p>
        </main>
    )
  }
}

export default PrivacyPolicy