import React from 'react'
import AnnouncementBanner from '../components/AnnouncementBanner'
import Footer from '../components/Footer'
import HeroBanner from '../components/HeroBanner'
import Navigation from '../components/Navigation'
import SearchBanner from '../components/SearchBanner'
import "../styles/pages/HomePage.css";

function HomePage() {
  return (
    <div>
        {/* Annoucement Banner */}
        <AnnouncementBanner/>

        {/* Navigation */}
        <Navigation/>
        <HeroBanner/>
        <main className="main">
        {/* Hero Banner */}
        

            <h2>Main Content</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor ac augue sed mollis. Praesent vestibulum arcu velit, vitae aliquet sem faucibus id. Vestibulum et vestibulum enim. Pellentesque vel odio magna. Vivamus pulvinar sapien eget dolor blandit, at varius arcu ultricies. Fusce consequat vitae turpis eget mollis. Integer lacinia, augue quis hendrerit ultricies, velit magna auctor velit, sed consectetur enim tortor vel arcu. Nulla facilisi. Quisque pulvinar, metus vel imperdiet luctus, nisl ipsum pharetra ante, vel malesuada felis nulla non elit. Duis non velit augue. Sed consequat justo eu quam faucibus, at bibendum nulla posuere. Fusce porttitor volutpat dui. Duis sollicitudin euismod lacus, quis imperdiet neque blandit et</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor ac augue sed mollis. Praesent vestibulum arcu velit, vitae aliquet sem faucibus id. Vestibulum et vestibulum enim. Pellentesque vel odio magna. Vivamus pulvinar sapien eget dolor blandit, at varius arcu ultricies. Fusce consequat vitae turpis eget mollis. Integer lacinia, augue quis hendrerit ultricies, velit magna auctor velit, sed consectetur enim tortor vel arcu. Nulla facilisi. Quisque pulvinar, metus vel imperdiet luctus, nisl ipsum pharetra ante, vel malesuada felis nulla non elit. Duis non velit augue. Sed consequat justo eu quam faucibus, at bibendum nulla posuere. Fusce porttitor volutpat dui. Duis sollicitudin euismod lacus, quis imperdiet neque blandit et</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor ac augue sed mollis. Praesent vestibulum arcu velit, vitae aliquet sem faucibus id. Vestibulum et vestibulum enim. Pellentesque vel odio magna. Vivamus pulvinar sapien eget dolor blandit, at varius arcu ultricies. Fusce consequat vitae turpis eget mollis. Integer lacinia, augue quis hendrerit ultricies, velit magna auctor velit, sed consectetur enim tortor vel arcu. Nulla facilisi. Quisque pulvinar, metus vel imperdiet luctus, nisl ipsum pharetra ante, vel malesuada felis nulla non elit. Duis non velit augue. Sed consequat justo eu quam faucibus, at bibendum nulla posuere. Fusce porttitor volutpat dui. Duis sollicitudin euismod lacus, quis imperdiet neque blandit et</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor ac augue sed mollis. Praesent vestibulum arcu velit, vitae aliquet sem faucibus id. Vestibulum et vestibulum enim. Pellentesque vel odio magna. Vivamus pulvinar sapien eget dolor blandit, at varius arcu ultricies. Fusce consequat vitae turpis eget mollis. Integer lacinia, augue quis hendrerit ultricies, velit magna auctor velit, sed consectetur enim tortor vel arcu. Nulla facilisi. Quisque pulvinar, metus vel imperdiet luctus, nisl ipsum pharetra ante, vel malesuada felis nulla non elit. Duis non velit augue. Sed consequat justo eu quam faucibus, at bibendum nulla posuere. Fusce porttitor volutpat dui. Duis sollicitudin euismod lacus, quis imperdiet neque blandit et</p>
            
        </main>

        {/* Footer */}
        <Footer/>
    </div>
  )
}

export default HomePage