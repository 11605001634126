import React, { Component } from 'react'
import AnnouncementBanner from '../components/AnnouncementBanner'
import Footer from '../components/Footer'
import Navigation from '../components/Navigation'
import SearchBanner from '../components/SearchBanner'
import "../styles/pages/ServicesPage.css";
import tech_img from "../assets/tech_consulting.jpg";
import biz_img from "../assets/business_consulting.jpg";

const services = [
    {
        id: 'entity-formation',
      title: 'Entity Formation',
      description: "We understand that setting up a business can be a daunting task. That's why our entity formation services are designed to make the process as smooth and stress-free as possible. From choosing the right legal structure to filing the necessary paperwork, we'll guide you every step of the way.",
      image: biz_img,
    },
      {
        id: 'brand-development',
      title: 'Brand Development',
      description: "Your brand is the face of your business. It's how people recognize and remember you. Our brand development services are designed to help you create a strong, cohesive brand that resonates with your target audience. From developing your brand strategy to creating your brand identity, we'll help you create a brand that truly reflects your business.",
      image: biz_img,
    },
    {
        id: 'web-development',
      title: 'Web Development',
      description: "In today's digital age, having a strong online presence is essential. Our web development services are designed to help you create a website that not only looks great but also functions seamlessly. From designing the layout to optimizing for search engines, we'll make sure your website stands out from the competition.",
      image: tech_img,
    },
    {
        
      id: 'technology-consulting',
      title: 'Technology Consulting',
      description: "In today's digital world, technology is more important than ever. Our technology consulting services are designed to help you leverage the latest technology to improve your business operations and drive growth. From implementing new software to improving your cybersecurity, we'll help you stay ahead of the curve.",
      image: tech_img,
    },
    {
        id: 'business-consulting',
      title: 'Business Consulting',
      description: "Running a business is no easy task. Our business consulting services are designed to help you overcome the challenges and achieve your goals. Whether you need help with financial planning, marketing strategy, or anything in between, we'll work with you to develop a tailored plan that meets your unique needs.",
      image: biz_img,
    },
    
  ];

  function ServiceSection({ id, title, description, image }) {
    return (
      <section className="service-section" id={id}>
        <img src={image} alt={title} />
        <h2>{title}</h2>
        <p>{description}</p>
        <button>Learn More</button>
      </section>
    );
  }

export class ServicesPage extends Component {
  render() {
    return (
      <div>
      {/* Annoucement Banner */}
      <AnnouncementBanner/>
      
      {/* Navigation */}          
      <Navigation/>
      
      {/* Navigation
      <SearchBanner/> */}
      
      {/* <main className="main"> */}
      <div className="services-page">
      {services.map((service) => (
          <ServiceSection key={service.title} {...service}/>
      ))}
      </div>
      {/* </main> */}
      
      {/* Footer */}          
      <Footer/>

      </div>
    )
  }
}

export default ServicesPage