import React, { Component } from 'react';
import "../styles/components/AnnoucementBanner.css";

export class AnnouncementBanner extends Component {
  render() {
    return (
      <div className='ab-container'>
      <div className='ab-items'>
      <a href="/" className="ab-item">
      W.R.K.Fullerton
      </a>
      </div>
      </div>
    )
  }
}

export default AnnouncementBanner