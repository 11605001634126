import React, { Component } from 'react'
import logo from "../assets/logo.png";
import "../styles/components/Navigation.css";

export class Navigation extends Component {
  render() {
    return (
      <div className="nav-container">
        <a href="/">
        <img src={logo} alt="logo" className="nav-logo"/>
        </a>

        {/* <!-- Navigation toggle button --> */}
        {/* <input type="checkbox" id="nav-toggle" className="nav-toggle"/>
        <label for="nav-toggle" className="nav-toggle-label">&#9776;</label> */}

        <ul className='nav-list'>
            <li className="nav-item"><a href="/services" className="nav-link">Services</a></li>
            <li className="nav-item"><a href="/insights" className="nav-link">Articles</a></li>
            <li className="nav-item"><a href="/newsletter" className="nav-link">Newsletter</a></li>
            <li className="nav-item"><a href="/about" className="nav-link">About</a></li>
            <li className="nav-item"><a href="/contact-us" className="nav-link">Contact</a></li>
        </ul>
      </div>
    )
  }
}

export default Navigation