import React, { Component } from 'react'
import AnnouncementBanner from '../components/AnnouncementBanner'
import Footer from '../components/Footer'
import HeroBanner from '../components/HeroBanner'
import Navigation from '../components/Navigation'
import SearchBanner from '../components/SearchBanner'
import "../styles/pages/AboutPage.css";
import wrkf1 from "../assets/wrkf_1.JPG";
import TimeLine from '../components/TimeLine'

export class AboutPage extends Component {
  render() {
    return (
      <div>
       {/* Annoucement Banner */}
       <AnnouncementBanner/>
      
      {/* Navigation */}          
      <Navigation/>
      
      {/* Navigation
      <SearchBanner/> */}

      <main>
      
    
      {/* <div className="about">

      <div className="about-content">
      
        <h1>Our History</h1>
      <p>We are a team of experienced business consultants dedicated to helping businesses achieve their goals.</p>
      <p>With years of experience in the industry, we have helped countless businesses overcome challenges and succeed in today's competitive marketplace.</p>
      <p>Our services include entity formation, brand development, web development, business consulting, and technology consulting. We work closely with our clients to develop tailored solutions that meet their unique needs and goals.</p>
      <p>Contact us today to learn more about how we can help your business succeed.</p>
      </div>
      <div className="about-image">
        <img src={wrkf} alt="Alt text for image" />
        </div>
      </div>  */}

      {/* <div className="about-page">
      <div className="history-section">
        <h2>Our History</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec convallis leo. Vestibulum vel lacus quis turpis maximus pretium. Praesent rutrum justo nec posuere fringilla. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Proin tincidunt euismod augue eu porta. Fusce nec tincidunt justo. Donec lacinia nulla eu velit dapibus, in vulputate mauris bibendum. Sed vel erat risus. Nulla et lectus faucibus, pharetra elit at, sodales justo. Vivamus sit amet rhoncus sapien. Sed laoreet eros nec vestibulum fermentum. Nunc bibendum leo nec risus dictum aliquet. Mauris rutrum ultricies felis, non commodo nibh commodo eget. Sed vehicula, risus nec vestibulum semper, augue enim consequat justo, ut bibendum quam mauris vel augue. Duis ac tincidunt felis. Sed eget elit ut orci volutpat malesuada ut vel justo.</p>
        <div className="founder-photos">
          <img src={wrkf} alt="Founder 1" />
        </div>
      </div>
      <div className="mission-section">
        <h2>Our Mission</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec convallis leo. Vestibulum vel lacus quis turpis maximus pretium. Praesent rutrum justo nec posuere fringilla. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Proin tincidunt euismod augue eu porta. Fusce nec tincidunt justo. Donec lacinia nulla eu velit dapibus, in vulputate mauris bibendum. Sed vel erat risus. Nulla et lectus faucibus, pharetra elit at, sodales justo. Vivamus sit amet rhoncus sapien. Sed laoreet eros nec vestibulum fermentum. Nunc bibendum leo nec risus dictum aliquet. Mauris rutrum ultricies felis, non commodo nibh commodo eget. Sed vehicula, risus nec vestibulum semper, augue enim consequat justo, ut bibendum quam mauris vel augue. Duis ac tincidunt felis. Sed eget elit ut orci volutpat malesuada ut vel justo.</p>
      </div>
    </div> */}
    <div className="about-page">
      <div className="founder-section">
        {/* <h2>Meet Our Founder</h2> */}
        <div className="founder-info">
        <h2>About Will Fullerton</h2>
          <div className="founder-photo">
          {/* <img src={wrkf1} alt="Founder 1" /> */}
          </div>
          <div className="founder-text">
          <div className="founder-photo-1">
          <img src={wrkf1} alt="Founder 1" />
          </div>
            <h3>Will Fullerton</h3>
            <p>Will Fullerton is an technology and business consultant.</p>
            <p>Graduated from Texas Christian University in 2018, with a degree in Economics and a minor in Asians Studies and Chinese (Mandarian).</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
             Sed nec convallis leo. Vestibulum vel lacus quis turpis maximus pretium.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec convallis leo. Vestibulum vel lacus quis turpis maximus pretium.</p>
            
          </div>
        </div>
      </div>
      
      {/* <div className="mission-section"> */}
        {/* <h2>Our Mission</h2> */}
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec convallis leo. Vestibulum vel lacus quis turpis maximus pretium. Praesent rutrum justo nec posuere fringilla. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Proin tincidunt euismod augue eu porta. Fusce nec tincidunt justo. Donec lacinia nulla eu velit dapibus, in vulputate mauris bibendum. Sed vel erat risus. Nulla et lectus faucibus, pharetra elit at, sodales justo. Vivamus sit amet rhoncus sapien. Sed laoreet eros nec vestibulum fermentum. Nunc bibendum leo nec risus dictum aliquet. Mauris rutrum ultricies felis, non commodo nibh commodo eget. Sed vehicula, risus nec vestibulum semper, augue enim consequat justo, ut bibendum quam mauris vel augue. Duis ac tincidunt felis. Sed eget elit ut orci volutpat malesuada ut vel justo.</p> */}
        {/* <a href="path/to/founder-page">Contact Will</a> */}
      {/* </div> */}
    </div>
    
      </main>
      <TimeLine/>
      <Footer/>
      </div>
    )
  }
}

export default AboutPage