import React, { Component } from 'react';
import AnnouncementBanner from '../components/AnnouncementBanner'
import Footer from '../components/Footer'
import HeroBanner from '../components/HeroBanner'
import Navigation from '../components/Navigation'
import SearchBanner from '../components/SearchBanner'
import "../styles/pages/ContactPage.css";

export class ContactPage extends Component {
  render() {
    return (
      <div>
       {/* Annoucement Banner */}
       <AnnouncementBanner/>
      
      {/* Navigation */}          
      <Navigation/>
      
      {/* Navigation
      <SearchBanner/> */}

      <div className="contact-wrapper">
      <div className="form-wrapper">
        <h2>Contact Will</h2>
        <form>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone:</label>
            <input type="tel" id="phone" name="phone" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" rows="5" required></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
      <div className="contact-info">
        <h2>Contact Information</h2>
        <div className="info-group">
          <h3>Will Fullerton</h3>
          <p>Email: will@wrkfullerton.com</p>
          <p>Phone: (214)-801-1228</p>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
    )
  }
}

export default ContactPage