import React, { Component } from 'react';
import AnnouncementBanner from '../components/AnnouncementBanner'
import Footer from '../components/Footer'
import HeroBanner from '../components/HeroBanner'
import Navigation from '../components/Navigation'
import SearchBanner from '../components/SearchBanner'
import "../styles/pages/NewsletterPage.css";

export class NewsletterPage extends Component {
  render() {
    
    return (
      <div>
      {/* Annoucement Banner */}
      <AnnouncementBanner/>
      
      {/* Navigation */}          
      <Navigation/>
      
      {/* Navigation
      <SearchBanner/> */}

      <main>
      {/* <form onSubmit={handleSubmit}> */}
      <div className="newsletter-block">
      <h2>Sign up for our newsletter</h2>
      <form>
        <label htmlFor="email-input">Email:</label>
        <input
          type="email"
          id="email-input"
        //   value={email}
        //   onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Subscribe</button>
      </form>
      </div>
      </main>
      <Footer/>
      </div>
    )
  }
}

export default NewsletterPage