import React, { Component } from 'react'

export class FooterLinkHeading extends Component {
  render() {
    return (
        <h4 className="footer__heading">{this.props.text}</h4>
    )
  }
}

export default FooterLinkHeading