import React, { Component } from 'react'
import "../styles/components/TimeLine.css";
import wrkf1 from "../assets/wrkf_1.JPG";
import wrkf2 from "../assets/wrkf_1.JPG";
import wrkf3 from "../assets/wrkf_1.JPG";

export class TimeLine extends Component {
  render() {
    return (
        <div className="timeline-section">
        <div className="timeline">
          <div className="timeline-item">
            
            <img src={wrkf1} alt="About" />
            <div className="timeline-date">2005</div>
            <div className="timeline-caption">Lorem ipsum dolor sit amet</div>
          </div>
          <div className="timeline-item">
            <div className="timeline-date">2010</div>
            <img src={wrkf2} alt="About" />
            <div className="timeline-caption">Consectetur adipiscing elit</div>
          </div>
          <div className="timeline-item">
            <div className="timeline-date">2015</div>
            <img src={wrkf3} alt="About" />
            <div className="timeline-caption">Sed do eiusmod tempor incididunt</div>
          </div>
        </div>
        <div className="about-content">
          <h2>About Me</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris interdum vestibulum urna ac luctus. Sed sagittis libero id ipsum pellentesque sagittis. Sed in magna ut mauris pharetra facilisis. Sed ornare enim vel convallis elementum.</p>
        </div>
      </div>
    )
  }
}

export default TimeLine

// export class TimeLine extends Component {
//     render() {
//       return (
//         <div className="timeline-section">
//           <div className="timeline">
//             <div className="timeline-item">
//               <div className="timeline-date timeline-date-left">2005</div>
//               <div className="timeline-line"></div>
//               <div className="timeline-content timeline-content-right">
//                 <img src={wrkf1} alt="About" />
//                 <div className="timeline-caption">Lorem ipsum dolor sit amet</div>
//               </div>
//             </div>
//             <div className="timeline-item">
//               <div className="timeline-date timeline-date-right">2010</div>
//               <div className="timeline-line"></div>
//               <div className="timeline-content timeline-content-left">
//                 <img src={wrkf2} alt="About" />
//                 <div className="timeline-caption">Consectetur adipiscing elit</div>
//               </div>
//             </div>
//             <div className="timeline-item">
//               <div className="timeline-date timeline-date-left">2015</div>
//               <div className="timeline-line"></div>
//               <div className="timeline-content timeline-content-right">
//                 <img src={wrkf3} alt="About" />
//                 <div className="timeline-caption">Sed do eiusmod tempor incididunt</div>
//               </div>
//             </div>
//           </div>
//           <div className="about-content">
//             <h2>About Me</h2>
//             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris interdum vestibulum urna ac luctus. Sed sagittis libero id ipsum pellentesque sagittis. Sed in magna ut mauris pharetra facilisis. Sed ornare enim vel convallis elementum.</p>
//           </div>
//         </div>
//       );
//     }
//   }
  
//   export default TimeLine;