import React, { Component } from 'react'

export class FooterLegalLink extends Component {
  render() {
    return (
    <a className='legal__links' href={this.props.href} target="_self">
    {this.props.text}
    </a>
    )
  }
}

export default FooterLegalLink